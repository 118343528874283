import React, { useState } from "react"
import {
  Navigation,
  Footer,
  Hero,
  Breadcrumb,
} from "../../../components/Marketing"
import { Seo } from "../../../components/Seo/Seo"
import { SeoLink } from "../../../components/SeoLink/SeoLink"
import { Haikus } from "../../../components/article/Haikus"

type Props = {
  pageContext: {
    theme: {
      label: string
      id: string
    }
    haikus: {
      content: string
      id: string
      author: string
    }[]
  }
}

export default ({ pageContext }: Props) => {
  const theme = pageContext.theme

  const breadcrumbs = [
    { label: "Recueils de haïkus", url: "/recueils/" },
    { label: "Par thématiques", url: "/recueils/thematiques/" },
    {
      label: theme.label,
      url: "/recueils/thematiques/" + theme.label + "/",
    },
  ]

  const label = theme.label.toLowerCase()

  return (
    <>
      <Seo
        title={`Exemple de haïkus dans la thématique ${label}`}
        description={`Ce recueil de haïkus dans le thème ${label} est parfait pour trouver des exemples et de l'inspiration.`}
        breadcrumbs={breadcrumbs}
      />
      <Navigation />
      <Hero
        theme={{
          as: "div",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          value: (
            <>
              Exemple de haïkus dans la thématique{" "}
              <span className="text-green-600">{label}</span>
            </>
          ),
        }}
        description={{
          as: "div",
          value: (
            <>
              <p>
                Bienvenu dans le recueil d'exemple de haïkus de la thématique{" "}
                {label}. <br />
                Vous pouvez lire ci dessous pleins{" "}
                <strong className="font-medium text-green-600">
                  d'exemple de haïkus dans la thématique {label}
                </strong>{" "}
                qui sont tous issus de notre base de données d'anciens auteurs
                de haïkus.
                <br />
                Plus nous ajouterons d'exemples de haïkus dans le thème {label},
                plus cette page grandira et évoluera automatiquement.
                <br />
                <br />
                Si vous souhaitez contribuer en publiant des haïkus, vous pouvez
                le faire depuis{" "}
                <SeoLink
                  isAsync
                  to="https://editeur.temple-du-haiku.fr/ecrire/"
                  className="font-medium text-green-600 underline"
                >
                  la page de création d'haïkus
                </SeoLink>
                . En revanche, si vous avez pleins d'exemples de haïkus dans le
                thème {label} ou autre, vous pouvez nous contacter sur les
                réseaux sociaux ou depuis{" "}
                <SeoLink
                  isAsync
                  to="/nous-contacter/"
                  className="font-medium text-green-600 underline"
                >
                  la page de contact
                </SeoLink>
                .
                {/* <br />
                Si vous restez sur votre faim, vous pouvez également aller voir{" "}
                <Link to="/repertoire/auteurs/">
                  Exemple de haïkus sur le thème {theme.label} écrit par notre
                  communauté
                </Link>. */}
              </p>
            </>
          ),
        }}
      />

      <Haikus
        haikus={pageContext.haikus.map(haiku => ({
          content: haiku.content,
          author: {
            fullName: haiku.author,
          },
        }))}
      />

      <Footer />
    </>
  )
}
