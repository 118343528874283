import { Link } from "gatsby"
import React, { useState } from "react"
import { Arrows } from "../Arrows/Arrows"
import { As } from "../As/As"

type Props = {
  title?: string
  haikus: Array<{
    content: string
    category?: string
    author?: {
      fullName: string
      url?: string
    }
  }>
}

const Dots: React.FC<{ actual: number; length: number }> = props => (
  <div className="absolute bottom-0 left-0 right-0 z-30 flex items-center justify-center max-w-2xl pb-4 mx-auto space-x-1">
    {Array.from({ length: props.length }).map((e, index) => (
      <div
        key={index}
        className={`${
          props.actual === index ? "" : ""
        } flex items-center justify-center transition-all duration-300 ease-in-out transform`}
      >
        <div
          className={`${
            props.actual === index ? "opacity-100" : "opacity-0"
          } relative w-5 h-5 bg-green-100 rounded-full`}
        ></div>

        <div
          className={`absolute w-2 h-2 z-10 rounded-full transition-all ease-in-out duration-300 ${
            props.actual === index ? "bg-green-500" : "bg-gray-200"
          }`}
        ></div>
      </div>
    ))}
  </div>
)

const Pagination: React.FC<{ actual: number; length: number }> = props => (
  <div className="absolute bottom-0 left-0 right-0 z-30 flex items-center justify-center max-w-2xl pb-4 mx-auto space-x-1 text-gray-400">
    {props.actual + 1} sur {props.length}
  </div>
)

export const Haikus: React.FC<Props> = props => {
  const [selected, setSelected] = useState(0)
  const { content, author, category } = props.haikus[selected]
  const hasLeft = selected > 0
  const hasRight = selected < props.haikus.length - 1

  return (
    <section className="relative py-20 mt-8 overflow-hidden bg-gray-50">
      <div className="relative max-w-2xl mx-auto">
        <div className="relative px-4 mx-auto sm:px-6 lg:px-8">
          {props.title && (
            <p className="relative z-10 block text-sm font-semibold tracking-wide text-center text-gray-400 uppercase sm:text-base lg:text-sm xl:text-base">
              {props.title}
            </p>
          )}

          <blockquote className="flex justify-center mt-10">
            <div>
              <div
                className="relative z-10 max-w-3xl mx-auto font-serif text-2xl italic font-medium leading-9 text-gray-900"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {author && author.url && (
                <Link
                  to={author.url}
                  className={`hover:text-green-500 hover:underline block z-10 relative pl-2 mt-10 font-serif text-base italic font-medium text-gray-400 border-l-2 border-gray-200`}
                >
                  {author.fullName}
                </Link>
              )}

              {author && !author.url && (
                <span
                  className={`block z-10 relative pl-2 mt-10 font-serif text-base italic font-medium text-gray-400 border-l-2 border-gray-200`}
                >
                  {author.fullName}
                </span>
              )}

              {category && (
                <span className="inline-flex items-center px-2 py-1 mt-8 text-sm font-medium text-gray-700 capitalize bg-gray-100 rounded-md">
                  {category}
                </span>
              )}
            </div>
          </blockquote>
        </div>
      </div>

      {props.haikus.length > 4 && (
        <Pagination actual={selected} length={props.haikus.length} />
      )}

      {props.haikus.length <= 4 && (
        <Dots actual={selected} length={props.haikus.length} />
      )}

      <Arrows
        hasLeft={hasLeft}
        hasRight={hasRight}
        onLeft={() => setSelected(selected - 1 || 0)}
        onRight={() => setSelected(selected + 1)}
      />
    </section>
  )
}
