import React from "react"

const Arrow: React.FC<{ className?: string }> = ({
  className = "",
  children,
}) => (
  <div
    className={`flex items-center justify-center w-12 h-12 text-gray-800 ${className}`}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-6 h-6"
    >
      {children}
    </svg>
  </div>
)

export const Arrows: React.FC<{
  onLeft: Function
  onRight: Function
  hasLeft: boolean
  hasRight: boolean
}> = ({ onLeft, onRight, hasLeft, hasRight }) => (
  <div className="absolute top-0 bottom-0 w-full mt-auto mb-auto">
    {hasLeft && (
      <div
        onClick={() => onLeft()}
        className="absolute top-0 bottom-0 left-0 flex items-center w-1/2 h-full pl-6 transition-all duration-300 ease-in-out cursor-pointer hover:from-green-100 bg-gradient-to-r from-gray-200 to-transparent"
      >
        <Arrow>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </Arrow>
      </div>
    )}
    {hasRight && (
      <div
        onClick={() => onRight()}
        className="absolute top-0 bottom-0 right-0 flex items-center w-1/2 h-full pr-6 transition-all duration-300 ease-in-out cursor-pointer hover:from-green-100 bg-gradient-to-l from-gray-200 to-transparent"
      >
        <Arrow className="ml-auto -mr-2">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </Arrow>
      </div>
    )}
  </div>
)
